import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Vimeo } from "@swizec/gatsby-theme-course-platform";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1 {...{
      "id": "intro-videos"
    }}>{`Intro videos`}</h1>
    <p>{`Watch these videos before the workshop. They'll help you gain a base understanding and make the workshop go smoother.`}</p>
    <p>{`That way we can focus on fun practice instead of boring lectures 😊`}</p>
    <h2 {...{
      "id": "why-jamstack-and-what-it-is"
    }}>{`Why JAMStack and what it is`}</h2>
    <Vimeo id={474198253} mdxType="Vimeo" />
    <h2 {...{
      "id": "webapp-vs-website"
    }}>{`Webapp vs. Website`}</h2>
    <Vimeo id={474198370} mdxType="Vimeo" />
    <p>{`Webapps and websites use similar technologies, but their goals are different. Knowing which you're building changes how you approach technological decisions.`}</p>
    <h2 {...{
      "id": "choosing-a-jamstack-provider"
    }}>{`Choosing a JAMStack provider`}</h2>
    <Vimeo id={474198523} mdxType="Vimeo" />
    <h2 {...{
      "id": "can-you-make-your-own-jamstack"
    }}>{`Can you make your own JAMStack`}</h2>
    <Vimeo id={474198640} mdxType="Vimeo" />
    <p>{`You can build your own JAMStack, lots of companies do. But is that the business you want to be in?`}</p>
    <h2 {...{
      "id": "how-jamstack-helps-you-ship"
    }}>{`How JAMStack helps you ship`}</h2>
    <Vimeo id={474198742} mdxType="Vimeo" />
    <p>{`What is it about the JAMStack that makes development faster and more painless.`}</p>
    <h2 {...{
      "id": "how-static-first-works"
    }}>{`How static-first works`}</h2>
    <Vimeo id={474198883} mdxType="Vimeo" />
    <p>{`The core idea behind static-first apps and how it happens. This is going to be our biggest focus during the workshop.`}</p>
    <h2 {...{
      "id": "which-jamstack-framework-to-pick"
    }}>{`Which JAMStack framework to pick?`}</h2>
    <Vimeo id={474199012} mdxType="Vimeo" />
    <p>{`How to choose a JAMStack framework for your usecase.`}</p>
    <h2 {...{
      "id": "design-systems"
    }}>{`Design systems`}</h2>
    <Vimeo id={474199119} mdxType="Vimeo" />
    <p>{`Why design systems are wonderful, help you ship faster, and divorce the designing of your app from implementation. Like the good old days of CSS vs. HTML+JS, but with all the modern conveniences.`}</p>
    <h2 {...{
      "id": "markdown-and-mdx"
    }}>{`Markdown and MDX`}</h2>
    <Vimeo id={474199223} mdxType="Vimeo" />
    <p>{`Why markdown and mdx are the best way to create the content for your site.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      